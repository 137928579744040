.portfolio-page {
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
  position: fixed;
  height: 100%;
  overflow: hidden;

  h1.page-title {
    margin-top: 5vh;
    font-size: 3.5vw;
    font-family: 'Coolvetica';
    color: #ddd3d8;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 2.5vh;
    left: 1vw;
  }

  .images-container {
    display: flex;
    gap: 2vw;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 10vh;
    gap: 20px;
    justify-content: center;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 50vh;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 2.5vw);

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: -webkit-fill-available;
      z-index: 3;
      padding: 1vh 2vw;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -7vh;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 2vw;
      font-weight: 500;
      line-height: 1.5;
    }

    .description {
      font-size: 1.2vw;
      margin-bottom: 0.5vh;
      color: #fff;
      font-weight: 500;
    }

    .btn {
      margin-top: 3vh;
      margin-bottom: 1vh;
      padding: 0 2vw;
      height: 4vh;
      line-height: 3.4vh;
      border: 0.2vw solid #ddd3d8;
      border-radius: 4px;
      font-size: 1.2vw;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-0.3vh);
      background: #8d8689;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #181818, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-page .images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .portfolio-page h1.page-title {
    margin-top: 5vh;
    font-size: 10.5vw;
    font-family: 'Coolvetica';
    color: #ddd3d8;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 2.5vh;
    left: 1vw;
  }

  .portfolio-page .image-box {
    flex: 1 1 50%; /* Display two image-boxes per row */
    max-width: calc(50% - 20px); /* Adjust max-width accordingly */
  }
}
