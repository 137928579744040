.logo-container {
  z-index: 0;
  width: 600px;
  height: 609px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 20%;
  left: 55%;
  margin: auto;
  opacity: 0;

  // svg {
  //   width: 100%;
  //   height: auto;
  //   bottom: 0;
  //   transform: rotateZ(30deg) !important;
  // }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    transform: rotateZ(30deg) !important;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ddd3d8;
  stroke-width: 10px;
}
